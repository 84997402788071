import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import 'animate.css';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/saasAppDark';
import Seo from 'components/seo';
import AppDownload from 'containers/SaasAppDark/AppDownload';
import Footer from 'containers/SaasAppDark/Footer';
import Navbar from 'containers/SaasAppDark/Navbar';
import { ContentWrapper, GlobalStyle } from 'containers/SaasAppDark/saasAppDark.style';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import Privacy from 'containers/App/Privacy';
const PrivacyPage = () => {

    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Seo title="Mambo AI ✨ | Privacy Policy" />
                <Modal />
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar />
                        </DrawerProvider>
                    </Sticky>
                    <Privacy />
                    <AppDownload />
                    <Footer />
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};

export default PrivacyPage;
