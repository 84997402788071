import React from 'react';
import Container from 'common/components/UI/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Section, { SectionContent } from './privacy.style';

const PrivacySection = () => {
    const data = useStaticQuery(graphql`
        query PrivacyPolicyQuery {
            iubendaDocument {
                privacyPolicy
            }
        }
    `);

    const { privacyPolicy } = data.iubendaDocument;

    return (
        <Section id="privacy">
            <Container width="1400px">
                <SectionContent>
                    <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                </SectionContent>
            </Container>
        </Section>
    );
};

export default PrivacySection;
